import { ExtendedProduct } from "product";
import { concat, equals, join, path, pathOr } from "ramda";

type NumberUtilsProps = {
  currency?: string;
  minimalDigits?: number;
  decimalDigits?: number;
  language?: string;
};

export type NumberUtilsStore = {
  toLocalizedString(value: number): string;
};

export const CUSTOM_CONSTANTS: { [key: string]: string } = {
  MOQ_TRIGGERED: "MoqTriggered",
};

const DEFAULT_CONFIGURATOR = "Fiber_Optic_Configurator";
const NO_CONFIGURATOR_TYPE = "No Type";
const CONFIGURATOR_TYPE_PATH = [
  "characteristicValues",
  "configurator-type",
  "values",
  0,
];
type ConfiguratorType =
  | "Fiber_Optic_Configurator"
  | "Servo_Assembly_Finder"
  | "Cable_Trolley_System"
  | "Test_Stecker"
  | "Spiral_Configurator";

export function determineConfigurator(
  product: ExtendedProduct,
): ConfiguratorType {
  return pathOr(DEFAULT_CONFIGURATOR, CONFIGURATOR_TYPE_PATH, product);
}

export function getConfiguratorType(product: ExtendedProduct): string {
  return pathOr(NO_CONFIGURATOR_TYPE, CONFIGURATOR_TYPE_PATH, product);
}

export function numberUtils({
  currency,
  minimalDigits,
  decimalDigits,
  language,
}: NumberUtilsProps): NumberUtilsStore {
  const numberFormat = Intl.NumberFormat(language, {
    minimumFractionDigits: minimalDigits ? decimalDigits : minimalDigits,
    maximumFractionDigits: decimalDigits,
    ...(currency && {
      currency,
      style: "currency",
    }),
  });

  function toLocalizedString(value: number): string {
    if (equals(typeof value, "number")) {
      return numberFormat.format(value);
    }
    throw new Error(`${value} is not a number`);
  }

  return { toLocalizedString };
}

export function numberUtilsCurrency(
  language: string,
  currency: string,
): NumberUtilsStore {
  return numberUtils({
    currency,
    language,
    decimalDigits: 2,
  });
}

export const disableGlobalScrolling = () => {
  document.body.style.height = "100%";
  document.body.style.overflow = "hidden";
  document.documentElement.style.scrollbarGutter = "stable";
};

export const enableGlobalScrolling = () => {
  document.body.style.height = "";
  document.body.style.overflow = "auto";
  document.documentElement.style.scrollbarGutter = "auto";
};

const thisScript = document.currentScript;
export function getScriptOrigin(): string {
  if (thisScript && thisScript instanceof HTMLScriptElement) {
    return new URL(thisScript.src).origin;
  }

  if (import.meta?.url) {
    return new URL(import.meta.url).origin;
  }

  throw new Error(
    "currentScript or import.meta seems to be undefined or can not be accessed. Therefore the app is unable to resolve its real host.",
  );
}

export const isStandalone = () =>
  equals(
    path<string>(["dataset", "standalone"], document.querySelector("#root")),
    "true",
  );

export const createUrl = (...segments: string[]) =>
  concat("/", join("/", segments));
