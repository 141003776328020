import { AxiosInstance } from "axios";
import { BomProduct, ButtonExposure, ButtonVisibility } from "bom";
import { ExtendedProduct } from "product";
import {
  compose,
  divide,
  equals,
  find,
  includes,
  isNil,
  map,
  not,
  path,
  pathOr,
  propEq,
  split,
} from "ramda";

import { requestCartId } from "../service/cartService";

export const SPARTACUS_AUTH = "spartacus⚿⚿auth";
export const SPARTACUS_CURRENCY = "spartacus⚿⚿currency";

const BUTTON_VISIBILITIES = [
  "hidden",
  "visible",
  "primary",
  "secondary",
  "alternate",
];

export const BUTTON_EXPOSURE = ["standalone", "embedded", "both"];

function isButtonVisibility(obj: unknown): obj is ButtonVisibility {
  return includes(obj, BUTTON_VISIBILITIES);
}

function isButtonExposure(obj: unknown): obj is ButtonExposure {
  return includes(obj, BUTTON_EXPOSURE);
}

export function toVisibility(product: ExtendedProduct) {
  return function (name: string): ButtonVisibility {
    const value = path<string | undefined>(
      ["characteristicValues", name, "values", 0],
      product,
    );
    return isButtonVisibility(value) ? value : "hidden";
  };
}

export function toExposure(product: ExtendedProduct) {
  return function (name: string): ButtonExposure {
    const value = path<string | undefined>(
      ["characteristicValues", name, "values", 0],
      product,
    );
    return isButtonExposure(value) ? value : "hidden";
  };
}

export function isLoggedIn() {
  return compose(
    not,
    isNil,
  )(
    path<string>(
      ["token", "access_token"],
      JSON.parse(localStorage.getItem(SPARTACUS_AUTH) ?? "{}"),
    ),
  );
}

function calculateAmountOfPackages(
  amount: number,
  packageSize: number,
): number {
  if (isNaN(packageSize)) {
    return amount;
  }
  return Math.ceil(divide(amount, packageSize));
}

export function prepareBom(bom: BomProduct[]) {
  return map((elem) => {
    const articleNumber =
      path<string>(
        ["values", 0],
        find(propEq("characteristicId", "Artikelnummer"))(
          pathOr([], ["productCharacteristics"], elem),
        ),
      ) || "";

    const packageSize =
      path<string>(
        ["values", 0],
        find(propEq("characteristicId", "pdm_atr_sap_vpe"))(
          pathOr([], ["productCharacteristics"], elem),
        ),
      ) ?? "1";

    const amount = calculateAmountOfPackages(
      pathOr(0, ["amount"], elem),
      parseInt(packageSize),
    );

    return {
      articleNumber,
      amount,
      isCable: pathOr(false, ["isCable"], elem),
    };
  }, bom);
}

export async function getCartId(
  axios: AxiosInstance,
  isLoggedIn: boolean,
  language: Locale,
  currency: string,
): Promise<string> {
  if (isLoggedIn) {
    return await requestCartId(axios, isLoggedIn, language, currency);
  }
  const cartInfo = JSON.parse(
    localStorage.getItem(`spartacus⚿${language.lang2}⚿cart`) ?? "{}",
  );
  const activeCartId = path(["active"], cartInfo);
  if (activeCartId && not(equals("", activeCartId))) {
    return activeCartId;
  }
  return await requestCartId(axios, isLoggedIn, language, currency);
}

const prepareButtonOrderValues = split(";");

export function getButtonOrder(product: ExtendedProduct): string[] {
  const value = pathOr<string>(
    "",
    ["characteristicValues", "bom-buttons-order", "values", 0],
    product,
  );
  return prepareButtonOrderValues(value);
}
