import { MessageBar, MessageBarType } from "@fluentui/react";
import { equals, isNil, path } from "ramda";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";
import { SettingsProps } from "setting";

import { ConfigurationService, GuiTO } from "@encoway/c-services-js-client";

import { ENCOWAY_ARTICLE_KEY, ENCOWAY_SESSION_KEY } from "../../DetermineRoute";
import { useConfigurationContext } from "../../context/useConfiguration";
import { ProductContext } from "../../context/useProducts";
import { useSettings } from "../../context/useSettings";
import { useDocumentTitle } from "../../hooks/useDocumentTitel";
import { PROGRESS, useProgress } from "../../hooks/useProgress";
import { determineConfigurator } from "../../utils";
import { Loading } from "../layout/Loading";
import { ConnectorConfigurator } from "./connector/ConnectorConfigurator";
import { FiberConfigurator } from "./fiber/FiberConfigurator";
import { ServoConfigurator } from "./servo/ServoConfigurator";
import { SpiralConfigurator } from "./spiral/SpiralConfigurator";
import { TrolleyConfigurator } from "./trolley/TrolleyConfigurator";

function CONFIGURATOR_OPTIONS(newSettings: SettingsProps) {
  return {
    [newSettings.studio.configurator.fiber]: FiberConfigurator,
    [newSettings.studio.configurator.servo]: ServoConfigurator,
    [newSettings.studio.configurator.trolley]: TrolleyConfigurator,
    [newSettings.studio.configurator.connector]: ConnectorConfigurator,
    [newSettings.studio.configurator.spiral]: SpiralConfigurator,
  };
}

export function LoadConfiguration() {
  const { settings } = useSettings();
  const { cfg, guiTO, actions } = useConfigurationContext();
  const { products } = useContext(ProductContext);
  const { session, article, load, locale, orderType } =
    useParams<ConfigurationRoutes>();
  const progress = useProgress();
  const navigate = useNavigate();
  useDocumentTitle(`${settings.name} ${guiTO?.translatedName || ""}`);

  function checkConfigCFG(_config: ConfigurationService | undefined) {
    if (_config) {
      if (orderType) {
        navigate(
          `/${locale}/configuration/${article}/${_config.id()}/mail/${orderType}`,
          { replace: true },
        );
      } else {
        navigate(`/${locale}/configuration/${article}/${_config.id()}`, {
          replace: true,
        });
      }
    }
  }

  async function loadConfig(config: {
    guiTO: GuiTO | undefined;
    cfg: ConfigurationService | undefined;
  }) {
    try {
      if (load && article) {
        config = await actions.loadSaved(article, load);
        if (config?.cfg) {
          localStorage.setItem(ENCOWAY_ARTICLE_KEY, article);
          localStorage.setItem(ENCOWAY_SESSION_KEY, config.cfg.id());
        }
      } else if (article && !session) {
        config = await actions.start(article);
      } else if (article && session) {
        if (!cfg || !equals(session, cfg.id())) {
          config = await actions.loadSession(article, session);
        }
        localStorage.setItem(ENCOWAY_ARTICLE_KEY, article);
        localStorage.setItem(ENCOWAY_SESSION_KEY, session);
        progress.set(PROGRESS.LOADED);
      }
      return config;
    } catch (e) {
      (document.querySelector("#root") as HTMLElement).dataset.shareId = "";
      localStorage.removeItem(ENCOWAY_ARTICLE_KEY);
      localStorage.removeItem(ENCOWAY_SESSION_KEY);
      navigate("/");
    }
  }

  useEffect(() => {
    (async function () {
      try {
        progress.set(PROGRESS.LOADING);
        let config = { cfg, guiTO };
        config = (await loadConfig(config)) ?? config;
        checkConfigCFG(config.cfg);
      } catch (e: any) {
        localStorage.removeItem(ENCOWAY_ARTICLE_KEY);
        localStorage.removeItem(ENCOWAY_SESSION_KEY);
        navigate("/");
      }
    })();
  }, [article, session, load]);

  if (progress.error) {
    return (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        onDismiss={() => navigate("/")}
        dismissButtonAriaLabel="back"
      >
        {progress.message}
      </MessageBar>
    );
  }

  if (cfg && progress.loaded) {
    const Configurator = path(
      [determineConfigurator(path([article || ""], products))],
      CONFIGURATOR_OPTIONS(settings),
    );
    if (isNil(Configurator)) {
      return null;
    }

    return <Configurator />;
  }

  return <Loading />;
}
