import { match, pathOr, replace, toLower } from "ramda";

import { Language } from "@encoway/c-services-js-client";

type LocaleMapping = {
  [key: string]: Array<string>;
};

// <language>-<country> -> [<language-code>, <country>]
export const LOCALE_MAPPING: LocaleMapping = {
  "en-de": ["en-US", "de-DE"],
  "en-at": ["en-US", "de-AT"],
  "en-hu": ["en-US", "hu-HU"],
  "en-fr": ["en-US", "fr-FR"],
  "en-be": ["en-US", "fr-BE"],
  "de-be": ["de-DE", "fr-BE"],
  "en-nl": ["en-US", "nl-NL"],
  "en-cz": ["en-US", "cs-CZ"],
  "en-pl": ["en-US", "pl-PL"],
  "en-en": ["en-US", "en-US"],
  "en-cn": ["en-US", "zh-CN"],
  "en-sr": ["en-US", "sr-RS"],
  "sr-sr": ["en-US", "sr-RS"],

  "en_cn-cn": ["en-US", "zh-CN"],
  "en_en-en": ["en-US", "en-US"],
  "en_sr-sr": ["en-US", "sr-RS"],
  "sr_sr-sr": ["en-US", "sr-RS"],
  "en_ca-ca": ["en-CA", "en-CA"],
  "en_us-us": ["en-US", "en-US"],
  "en_gb-gb": ["en-GB", "en-GB"],
};

export function toLanguage(
  acc: { [locale: string]: Language },
  { displayName, tag }: Language,
): { [locale: string]: Language } {
  return {
    ...acc,
    [tag]: { tag, displayName },
  };
}

/**
 * Retrieves the full location (URL) of the current webpage, excluding any trailing hash symbol.
 * @returns {string} The modified location string without the trailing hash symbol.
 */
export function getFullLocation(fallback: string): string {
  // eslint-disable-next-line no-restricted-globals
  if (window?.location?.href) {
    // eslint-disable-next-line no-restricted-globals
    const [location] = match(/[^#]+/, window.location.href);
    if (location) {
      return replace(/\/$/, "", location);
    }
  }
  return fallback;
}

export function mapLocale(
  languageMapping: LocaleMapping,
  locale = "",
): Array<string> {
  const fallback = [locale, locale];
  return pathOr(fallback, [toLower(locale)], languageMapping);
}
