import {
  IHoverCard,
  ITheme,
  mergeStyleSets,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { equals, map } from "ramda";
import { useMemo, useRef } from "react";

import { ParameterTO, Value } from "@encoway/c-services-js-client";
import { ComponentName } from "@encoway/cui-configurator-components";

import { Tooltip } from "../../../components/Tooltip";
import { useConfigurationContext } from "../../../context/useConfiguration";
import { useSettings } from "../../../context/useSettings";
import { mediaQuery } from "../../../theme";

function modalStyle(theme: ITheme) {
  return mergeStyleSets({
    heading: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "20px",
    },
    subHeading: {
      padding: ".5em 1em",
      display: "grid",
      gridTemplateColumns: "0fr 1fr",
      gap: "20px",
    },
    subHeadingText: {
      display: "flex",
      alignItems: "center",
    },
    table: {
      display: "flex",
      flexDirection: "column",
    },
    tableItem: {
      cursor: "pointer",
      padding: ".5em 1em",
      display: "flex",
      gap: "20px",
      selector: {
        "&.selected": {
          cursor: "initial",
          backgroundColor: theme.palette.neutralLight,
        },
        "&:hover": {
          backgroundColor: theme.palette.neutralLighter,
        },
        "&.selected:hover": {
          backgroundColor: theme.palette.neutralLight,
        },
      },
      [mediaQuery.sm]: {
        gap: "10px",
      },
    },
    imageWrapper: {
      justifyContent: "center",
      padding: ".5rem 1rem",
      [mediaQuery.sm]: {
        padding: ".5rem .3rem",
      },
    },
    image: {
      alignSelf: "center",
      width: "6em",
    },
    info: {
      alignSelf: "center",
    },
    infoTitle: {
      fontWeight: "bold",
    },
    infoText: {
      "*": {
        wordBreak: "break-word",
      },
      a: {
        color: theme.palette.themePrimary,
        textDecoration: "none",
      },
    },
  });
}

export function ConfParameterTooltip(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const { settings } = useSettings();
  const theme = useTheme();
  const modalStyled = useMemo(() => modalStyle(theme), [theme]);
  const { values } = props.data;
  const { table, tableItem, image, info, infoTitle, infoText } = modalStyled;
  const componentRef = useRef(null);
  const {
    actions: { checkIdentical },
  } = useConfigurationContext();

  function onChange(value: Value) {
    if (value.selected) {
      return;
    }
    checkIdentical(props.data.name, value.value);
    props.onValueChanged(props.data, value.value);
    if (componentRef.current) {
      (componentRef.current as IHoverCard).dismiss();
    }
  }

  return (
    <Tooltip componentRef={componentRef}>
      {!equals(props.data.viewPort, ComponentName.InputField) && (
        <div className={table}>
          {map(
            (value) => (
              <div
                key={value.value}
                onClick={() => onChange(value)}
                className={classNames(tableItem, {
                  selected: value.selected,
                })}
              >
                <Stack className={modalStyled.imageWrapper}>
                  {value.imageUrl && (
                    <img
                      className={image}
                      src={`${settings.showroom.url}/api/catalog/media?id=${value.imageUrl}`}
                      alt="Tooltip"
                    />
                  )}
                </Stack>
                <div className={info}>
                  <Text className={infoTitle}>{value.translatedValue}</Text>
                  <Text className={infoText}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: value.longText || "",
                      }}
                    />
                  </Text>
                </div>
              </div>
            ),
            values || [],
          )}
        </div>
      )}
    </Tooltip>
  );
}
