import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import { SimpleDialog } from "../../components/Dialog/SimpleDialog";

export function Reset() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { article } = useParams<ConfigurationRoutes>();
  const [open, setOpen] = useState<boolean>(false);

  function init() {
    const element = document.getElementsByClassName("navbar-item");
    if (element?.[0]) {
      element[0].addEventListener("click", function () {
        setOpen(true);
      });
    }
  }

  function onClose() {
    navigate(`/${i18n.language}/configuration/${article}`);
    setOpen(false);
  }

  useEffect(() => {
    setTimeout(init, 1000);
  }, [i18n.language]);

  return (
    <>
      <SimpleDialog
        ok={onClose}
        dismiss={() => setOpen(false)}
        okText={t("t:common.button.resetConfiguration")}
        cancelText={t("t:common.button.cancel")}
        label={t(
          "t:glossary.checkout.resetConfiguration.resetYourConfiguration",
        )}
        text={t("t:glossary.checkout.resetConfiguration.resetYesNo")}
        isOpen={open}
      />{" "}
    </>
  );
}
