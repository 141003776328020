import { ButtonExposure } from "bom";
import { Products } from "product";
import {
  anyPass,
  equals,
  filter,
  flatten,
  includes,
  isEmpty,
  isNil,
  map,
  not,
  pathOr,
  pick,
  split,
} from "ramda";

import { ContainerTO } from "@encoway/c-services-js-client";

import { BUTTON_EXPOSURE } from "../../../context/useBomUtils";
import { isStandalone } from "../../../utils";

const INDIVIDUAL_CHARACTERISTIC_PATH = (article: string) => [
  article,
  "characteristicValues",
  "bom-individual-characteristic",
  "values",
  0,
];

export const retrieveBomIndividualCharacteristic = (
  article: string,
  data: Products,
) =>
  split(",", pathOr<string>("", INDIVIDUAL_CHARACTERISTIC_PATH(article), data));

export function findSections(
  container: ContainerTO,
  sectionNames: string[],
  result: any[],
): ContainerTO[] {
  const found = filter(
    ({ name }) => includes(name, sectionNames),
    container.children,
  );

  const results = map(
    (child) => findSections(child, sectionNames, result),
    container.children,
  );

  if (isNil(found)) {
    return result;
  }

  return flatten([...result, ...results, ...found]);
}

export function extractParameters(sections: ContainerTO[]) {
  return pathOr(
    [],
    [0, "parameters"],
    flatten(map(pick(["parameters"]), sections)),
  );
}

const checker = (standalone: boolean) => [
  (value: ButtonExposure) => equals("both", value),
  (value: ButtonExposure) => equals("standalone", value) && standalone,
  (value: ButtonExposure) => equals("embedded", value) && !standalone,
];

export function retrieveButtonVisibility(value: ButtonExposure | undefined) {
  if (isNil(value) || isEmpty(value) || not(includes(value, BUTTON_EXPOSURE))) {
    return false;
  }
  return anyPass(checker(isStandalone()))(value);
}
