import { Bus } from "baconjs";
import { Bom, CfgBus, SalesContext, SelectedParameters } from "configuration";
import { StepMetaData } from "emosTypes";
import {
  equals,
  isNil,
  length,
  lt,
  mergeDeepRight,
  nth,
  reduce,
  slice,
  split,
  update,
} from "ramda";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ConfigurationService, GuiTO } from "@encoway/c-services-js-client";
import { translations as Tapp } from "@encoway/cui-application-components";
import { translations as Tconf } from "@encoway/cui-configurator-components";
import { L10n } from "@encoway/l10n";
import { Constants } from "@encoway/react-configurator";

import {
  doChangeStateIdenticalConfiguration,
  forceParameter,
  getCustomConfigurationView,
  loadConfigurationFromSavedId,
  saveConfigurationFromSessionId,
} from "../service/configurationService";
import {
  determineEmosIndex,
  findColumnIndex,
  findElement,
  findParent,
  findRootChildren,
  generateValue,
} from "../utils/econdaUtils";
import { toBom, toSelectedParameters } from "./configurationUtils";
import { useAnalyticsContext } from "./useAnalytics";
import { useSettings } from "./useSettings";

type CfgState = {
  article: string | undefined;
  cfg: ConfigurationService | undefined;
  guiTO: GuiTO | undefined;
  isIdenticalChecked: boolean;
  productCrossReferences: string[];
  crossReferencesCharacteristics: {
    [key: string]: string[];
  };
  boms: {
    [lang: string]: Bom[];
  };
};

type Configuration = {
  cfg: ConfigurationService;
  guiTO: GuiTO;
};

type ConfigurationActions = {
  start(
    productId?: string,
    salesContext?: SalesContext,
  ): Promise<Configuration>;
  loadSession(articleName: string, id: string): Promise<Configuration>;
  loadSaved(articleName: string, uuid: string): Promise<Configuration>;
  save(configurationId?: string): Promise<string>;
  selectedParameters(guiTO?: GuiTO): SelectedParameters;
  setIsIdenticalChecked(newState: boolean): void;
  checkIdentical(parameterName: string, value: string | number): void;
  setProductCrossReferences(references: string[]): void;
  setCrossReferencesCharacteristics(characteristics: {
    [key: string]: string[];
  }): void;
};

export type ConfigurationStore = {
  article: string | undefined;
  guiTO: GuiTO | undefined;
  cfg: ConfigurationService | undefined;
  bom: Bom[];
  actions: ConfigurationActions;
  eventBus: Bus<CfgBus> | undefined;
  isIdenticalChecked: boolean;
  productCrossReferences: string[];
  crossReferencesCharacteristics: {
    [key: string]: string[];
  };
};

const initialCfg: CfgState = {
  article: undefined,
  guiTO: undefined,
  cfg: undefined,
  isIdenticalChecked: false,
  productCrossReferences: [],
  boms: {},
  crossReferencesCharacteristics: {},
};

const ConfigurationContext = createContext<ConfigurationStore | null>(null);

const translations = mergeDeepRight(Tapp, Tconf);
L10n.source("Configuration", translations, true);

export function ConfigurationContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const { settings, http, axios } = useSettings();
  const { send } = useAnalyticsContext();
  const { i18n } = useTranslation();
  const eventBus = useMemo(() => new Bus<CfgBus>(), []);
  const [
    {
      cfg,
      guiTO,
      boms,
      article,
      isIdenticalChecked,
      productCrossReferences,
      crossReferencesCharacteristics,
    },
    setConfiguration,
  ] = useState<CfgState>(initialCfg);
  const stepMetaDataRef = useRef<StepMetaData>(["", "", ""]);

  async function getBoms(
    configurationId: string,
  ): Promise<{ [lang: string]: Bom[] }> {
    const fetchedBom = await getCustomConfigurationView(
      axios,
      configurationId,
      settings.studio.boms.view,
      i18n.language,
    );
    return {
      [i18n.language]: reduce(toBom, [], [fetchedBom.data.rootContainer]),
    };
  }

  async function start(
    articleName: string,
    salesContext?: SalesContext,
  ): Promise<Configuration> {
    const configuration = await ConfigurationService.create(
      http,
      settings.showroom.url,
      {
        articleName: articleName,
        ...(salesContext && { salesContext }),
      },
      i18n.language,
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING",
      },
    });
    const _guiTO = (await configuration.ui()) as GuiTO;
    const _boms = await getBoms(configuration.configurationId);
    const config = {
      article: articleName,
      guiTO: _guiTO,
      cfg: configuration,
      boms: _boms,
      isIdenticalChecked,
      productCrossReferences,
      crossReferencesCharacteristics,
    };
    setConfiguration(config);
    return config;
  }

  async function loadSession(
    articleName: string,
    confId: string,
  ): Promise<Configuration> {
    const configuration = await ConfigurationService.create(
      http,
      settings.showroom.url,
      { configurationId: confId },
      i18n.language,
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING",
      },
    });
    const _guiTO = (await configuration.ui()) as GuiTO;
    const config = {
      article: articleName,
      guiTO: _guiTO,
      cfg: configuration,
      boms: { big: [], small: [] },
      isIdenticalChecked,
      productCrossReferences,
      crossReferencesCharacteristics,
    };
    setConfiguration(config);
    return config;
  }

  /**
   * Loads a saved configuration based on an article name and UUID.
   *
   * @param articleName The name of the article associated with the saved configuration.
   * @param uuid The UUID (Universally Unique Identifier) of the saved configuration.
   * @return A Promise that resolves to a Configuration object.
   */
  async function loadSaved(
    articleName: string,
    uuid: string,
  ): Promise<Configuration> {
    const { data } = await loadConfigurationFromSavedId(axios, uuid);
    const configuration = new ConfigurationService(
      http,
      settings.showroom.url,
      data,
      i18n.language,
      true,
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING",
      },
    });
    const _guiTO = (await configuration.ui()) as GuiTO;
    const config = {
      article: articleName,
      guiTO: _guiTO,
      cfg: configuration,
      boms: { big: [], small: [] },
      isIdenticalChecked,
      productCrossReferences,
      crossReferencesCharacteristics,
    };
    setConfiguration(config);
    return config;
  }

  /**
   * Saves the configuration identified by the specified ID or the default configuration if no ID is provided.
   *
   * @param configurationId - Optional ID of the configuration to save. If not provided, the ID of the default configuration will be used.
   * @returns A Promise that resolves to a string representing the UUID of the saved configuration.
   */
  async function save(configurationId?: string): Promise<string> {
    if (cfg) {
      const { data } = await saveConfigurationFromSessionId(
        axios,
        configurationId ?? cfg.id(),
      );
      return data.uuid;
    }
    throw new Error("configuration is undefined");
  }

  function selectedParameters(_guiTO?: GuiTO): SelectedParameters {
    const internalGuiTO = _guiTO || guiTO;
    if (internalGuiTO?.rootContainer.children[0]) {
      return reduce(
        toSelectedParameters,
        {},
        internalGuiTO.rootContainer.children[0].parameters,
      );
    }
    throw new Error("Could not get selected parameters. GuiTO is undefined");
  }

  function setIsIdenticalChecked(newState: boolean): void {
    setConfiguration((prev) => ({ ...prev, isIdenticalChecked: newState }));
  }

  function checkIdentical(parameterName: string, value: string | number) {
    if (isIdenticalChecked && cfg) {
      forceParameter(axios, cfg.configurationId, parameterName, value).then();
    }
  }

  function setProductCrossReferences(references: string[]) {
    setConfiguration((prev) => ({
      ...prev,
      productCrossReferences: references,
    }));
  }

  function setCrossReferencesCharacteristics(characteristics: {
    [key: string]: string[];
  }): void {
    setConfiguration((prev) => ({
      ...prev,
      crossReferencesCharacteristics: characteristics,
    }));
  }

  useEffect(() => {
    return eventBus.onValue(async (e) => {
      if (equals(e.event, Constants.Events.UpdateState)) {
        const _guiTO = e.rawState;
        setConfiguration((prev) => ({
          ...prev,
          cfg: prev.cfg,
          guiTO: _guiTO,
          boms: { big: [], small: [] },
        }));
      }
    });
  }, [eventBus]);

  useEffect(() => {
    if (cfg) {
      const id = setInterval(() => cfg.status(), 60 * 2000);
      return () => clearInterval(id);
    }
  }, [cfg]);

  useEffect(() => {
    (async () => {
      await L10n.reloadResources(i18n.language);
      L10n.currentLocale(i18n.language);
      if (article && cfg) {
        const oldArticle = article;
        const configurationId = cfg.id();
        setConfiguration((prev) => ({
          ...prev,
          cfg: undefined,
          guiTO: undefined,
        }));
        await loadSession(oldArticle, configurationId);
      }
    })();
  }, [i18n.language]);

  useEffect(() => {
    if (guiTO) {
      return eventBus
        .filter(({ event }) => equals(event, Constants.Events.ParameterChanged))
        .onValue(async (event) => {
          const configPath = split("/", slice(1, Infinity, event.path!));
          if (!cfg || !configPath || lt(length(configPath), 3)) {
            return;
          }
          const rootChildren = findRootChildren(nth(0, configPath)!, guiTO);
          const index = findColumnIndex(nth(1, configPath)!, rootChildren);
          const parent = findParent(nth(1, configPath)!, rootChildren);
          const element = findElement(event.name, parent!);
          if (!parent || !element) {
            return;
          }
          const metaDataValue = generateValue(
            parent,
            element,
            event.value,
            nth(1, configPath)!,
            nth(2, configPath)!,
          );
          const updatedStepMetaDataRef = update(
            determineEmosIndex(cfg, index),
            metaDataValue,
            stepMetaDataRef.current,
          ) as StepMetaData;
          if (equals(updatedStepMetaDataRef, stepMetaDataRef.current)) {
            return;
          }
          stepMetaDataRef.current = updatedStepMetaDataRef;
          send(
            settings.analytics.content.configure,
            i18n.language,
            cfg,
            updatedStepMetaDataRef,
          );
        });
    }
  }, [guiTO]);

  useEffect(() => {
    if (cfg && isIdenticalChecked) {
      doChangeStateIdenticalConfiguration(axios, cfg.configurationId).then();
    }
  }, [isIdenticalChecked]);

  const value = useMemo(
    () => ({
      article,
      cfg,
      guiTO,
      eventBus,
      isIdenticalChecked,
      productCrossReferences,
      crossReferencesCharacteristics,
      bom: boms[i18n.language],
      actions: {
        start,
        loadSession,
        loadSaved,
        save,
        selectedParameters,
        setIsIdenticalChecked,
        checkIdentical,
        setProductCrossReferences,
        setCrossReferencesCharacteristics,
      },
    }),
    [
      article,
      cfg,
      eventBus,
      boms,
      guiTO,
      i18n.language,
      isIdenticalChecked,
      productCrossReferences,
      crossReferencesCharacteristics,
    ],
  );

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
}

export function useConfigurationContext() {
  const context = useContext(ConfigurationContext);
  if (isNil(context)) {
    throw new Error(
      "useConfigurationContext must be used within ConfigurationContextProvider",
    );
  }
  return context;
}
