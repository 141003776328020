import { useEffect } from "react";

import { useExternalHTML } from "../../hooks/useExternalHTML";

const closeCookieSettings = () => {
  const cookieCloseElement: HTMLCollectionOf<Element> =
    document.getElementsByClassName("onetrust-pc-dark-filter");
  if (cookieCloseElement?.[0]) {
    // @ts-expect-error might produce an error if element is not found
    // FIXME: replace with setAttribute(style, "display: none") in the future
    cookieCloseElement[0].style = "display: none";
  }
  document.removeEventListener("click", closeCookieSettings);
};

const getCookieListener = () => {
  setTimeout(() => {
    document
      .getElementById("close-pc-btn-handler")
      ?.addEventListener("click", closeCookieSettings);
  }, 1000);
};

export function Footer() {
  const { data } = useExternalHTML("footer");
  useEffect(() => {
    if (data) {
      document
        .getElementById("cookie")
        ?.addEventListener("click", getCookieListener);
      return () => {
        document.removeEventListener("cookie", getCookieListener);
      };
    }
  }, [data]);

  return data ? <div dangerouslySetInnerHTML={{ __html: data }} /> : null;
}
